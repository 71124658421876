<template>
  <div class="orderHistory">
    <div class="container-fluid">
      <div class="row grow w-100 mx-auto">
        <app-header></app-header>
        <div class="container">
          <div class="row text-center">
            <h3>Order History List</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import Header from "@/components/Header.vue";
// import axios from "axios";

export default {
  name: "OrderHistory",
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      autoGroupColumnDef: null,
      rowSelection: null,
      itemsCount: 0,
      priceListFor: "July",
      selectedRowsCount: 0
    };
  },
  components: {
    AgGridVue,
    "app-header": Header
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    getSelectedRows() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      const selectedDataStringPresentation = selectedData
        .map(node => `${node.item_number} ${node.brand}`)
        .join(", ");
      //alert(`Selected nodes: ${selectedDataStringPresentation}`);
      this.selectedRowsCount = selectedNodes.length;
      console.log("selected" + selectedRowsCount);
    }
  }
};
</script>
